//
//
//
//
//

export default {
    data() {
        return {
            option: {
                title: {
                    text: '湖北省各市今日气温'
                },
                tooltip: {},
                legend: {
                    data:['温度']
                },
                xAxis: {
                    data: []
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: '{value} °C'
                    }
                },
                series: [{
                    name: '温度',
                    type: 'bar',
                    data: []
                }]
            }
        }
    },
    mounted() {
        this.queryWeather();
    },
    methods: {
        //调用接口查询湖北省各市今日气温
        async queryWeather() {
            const {data:res} = await this.$http.post("/common/queryWeatherByProvince?name=湖北省");
            if (res.code === 'AAA' && res.data) {
                let tempchart = res.data;
                for(let temp of tempchart) {
                    this.option.xAxis.data.push(temp.city);
                    this.option.series[0].data.push(temp.temperature);
                }
            } else {
                this.$message.error("查询天气信息失败！");
            }
        }
    }
}
