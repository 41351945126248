//
//
//

export default {
    data() {
        return {
            nacosAuth: true,
            nacosUrl: ""
        }
    },
    created() {
        this.getNacosToken();
    },
    methods: {
        async getNacosToken() {
            const {data:res} = await this.$http.post("cloud/getNacosToken");
            if (res.code === 'AAA' && res.data != null) {
                window.localStorage.setItem("token", JSON.stringify(res.data));
                this.toNacosPage();
            } else {
                this.$message.error("nacos认证失败,无访问权限!");
            }
        },
        toNacosPage() {
            this.nacosAuth = false;
            this.nacosUrl = "/nacos";
        }
    }
}
