//
//
//
//
//

export default {
    data() {
        return {
            options: {
                title: {
                    text: '各产品销量'
                },
                tooltip: {},
                legend: {
                    data:['销量']
                },
                xAxis: {
                    data: ["衬衫","羊毛衫","雪纺衫","裤子","高跟鞋","袜子"]
                },
                yAxis: {},
                series: [{
                    name: '销量',
                    type: 'graph',
                    data: [5, 20, 36, 10, 10, 20]
                }]
            }
        }
    },
    mounted() {
        
    },
    methods: {
    }
}
