//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      dataList : [],
      addFlag : false,
      addForm : {
        name : '',
        lng : '',
        lat : '',
        highlight : false
      }
    }
  },
  created() {
    this.queryMapPointList();
  },
  methods: {
    async queryMapPointList() {
      const {data:res} = await this.$http.post("startMap/queryMapPointList");
      if (res.code === 'AAA') {
        this.dataList = res.data;
      } else {
        this.$message.error("查询失败！" + res.msg);
      }
    },
    showAddDiv() {
      this.addFlag = true;
    },
    async addMapPoint() {
      this.addFlag = false;
      const {data:res} = await this.$http.post("startMap/addMapPoint", this.addForm);
      if (res.code === 'AAA') {
        this.$message.success("新增成功！");
        this.queryMapPointList();
      } else {
        this.$message.error("新增失败！" + res.msg);
      }
    },
    async updateMapPoint(mapPoint) {
      const {data:res} = await this.$http.post("startMap/updateMapPoint", mapPoint);
      if (res.code === 'AAA') {
        this.$message.success("修改成功！");
        this.queryMapPointList();
      } else {
        this.$message.error("修改失败！" + res.msg);
      }
    },
    async deleteMapPoint(id) {
      const {data:res} = await this.$http.post("startMap/deleteMapPoint?id=" + id);
      if (res.code === 'AAA') {
        this.$message.success("删除成功！");
        this.queryMapPointList();
      } else {
        this.$message.error("删除失败！" + res.msg);
      }
    }
  }
}
