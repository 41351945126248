//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default  {
  data() {
     return  {
      content:  '' ,
      buttonPermission: [0,1],
      buttonDisabled:  false
    }
  },
  created() {
    this.checkPermission();
  },
  methods: {
    async addNotice() {
      this.buttonDisabled =  true;
      const {data:res} = await this.$http.post("notice/addNotice?content=" + this.content);
        if (res.code === 'AAA') {
          this.$message.info("发布成功！");
        } else {
          this.$message.error("发布失败！" + res.msg);
        }
        this.buttonDisabled =  false;
    },
    checkPermission() {
      this.buttonDisabled = this.$hasPermission(this.buttonPermission);
    }
  }
}
