//
//
//
//
//

export default {
    data() {
        return {
            option: {
                title: {
                    text: '武汉气温',
                    left: 'center',
                },
                tooltip: {},
                legend: {},
                series: [
                    {
                        type: 'gauge',
                        center: ['50%', '60%'],
                        startAngle: 200,
                        endAngle: -20,
                        min: -20,
                        max: 50,
                        splitNumber: 14,
                        itemStyle: {
                            color: '#FFAB91'
                        },
                        progress: {
                            show: true,
                            width: 30
                        },
                        pointer: {
                            show: false
                        },
                        axisLine: {
                            lineStyle: {
                                width: 30
                            }
                        },
                        axisTick: {
                            distance: -45,
                            splitNumber: 5,
                            lineStyle: {
                            width: 2,
                            color: '#999'
                            }
                        },
                        splitLine: {
                            distance: -52,
                            length: 14,
                            lineStyle: {
                            width: 3,
                            color: '#999'
                            }
                        },
                        axisLabel: {
                            distance: -20,
                            color: '#999',
                            fontSize: 20
                        },
                        anchor: {
                            show: false
                        },
                        title: {
                            show: false
                        },
                        detail: {
                            valueAnimation: true,
                            width: '60%',
                            lineHeight: 40,
                            borderRadius: 8,
                            offsetCenter: [0, '-15%'],
                            fontSize: 60,
                            fontWeight: 'bolder',
                            formatter: '{value} °C',
                            color: 'inherit'
                        },
                        data: [
                            {
                                value: 10,
                                name: '当前气温'
                            }
                        ]
                    },
                    {
                        type: 'gauge',
                        center: ['50%', '60%'],
                        startAngle: 200,
                        endAngle: -20,
                        min: -20,
                        max: 50,
                        itemStyle: {
                            color: '#FD7347'
                        },
                        progress: {
                            show: true,
                            width: 8
                        },
                        pointer: {
                            show: false
                        },
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: false
                        },
                        axisLabel: {
                            show: false
                        },
                        detail: {
                            show: false
                        },
                        data: [
                            {
                                value: 10,
                                name: '当前气温'
                            }
                        ]
                    }
                ]
            }
        }
    },
    mounted() {
        
    },
    created() {
        this.queryWeather();
    },
    methods: {
        //调用接口查询武汉天气情况
        async queryWeather() {
            const {data:res} = await this.$http.post("/common/queryWeatherByCode?code=57494");
            if (res.code === 'AAA' && res.data) {
                let temperature = res.data.real.weather.temperature;
                this.option.series[0].data[0].value = temperature;
                this.option.series[1].data[0].value = temperature;
            } else {
                this.$message.error("查询天气信息失败！");
            }
        }
    }
}
