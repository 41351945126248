//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            center: {lng: 106.950763, lat: 27.064885},
            zoom: 9,
            pointName: '',
            showFlag: false,
            imgUrl: '',
            marker: []
        }
    },
    mounted() {
    },
    methods: {
        async queryMapPointList() {
            const {data:res} = await this.$http.post("startMap/queryMapPointList");
            if (res.code === 'AAA') {
                this.dataList = res.data;
            } else {
                this.$message.error("查询失败！" + res.msg);
            }
        },
        async handler ({BMap, map}) {
            const {data:res} = await this.$http.post("startMap/queryMapPointList");
            if (res.code === 'AAA') {
                let data = res.data;
                //循环创建标记
                for (let i = 0; i < data.length; i++) {
                    let point = new BMap.Point(data[i].lng, data[i].lat);
                    //自定义market图标
                    let imgUrl = '';
                    if (data[i].highlight) {
                        imgUrl = require('../../assets/start1.jpg');
                    } else {
                        imgUrl = require('../../assets/start0.jpg');
                    }
                    let myIcon = new BMap.Icon(imgUrl, new BMap.Size(88, 30));
                    myIcon.setImageSize(new BMap.Size(88, 30));
                    //添加文字标签
                    let label = new BMap.Label(data[i].name, {offset: new BMap.Size(0, -20)});
                    label.setStyle({
                        color: "#fff",
                        fontSize: "14px",
                        height: "20px",
                        lineHeight: "20px",
                        fontFamily: "微软雅黑",
                        border: "none",
                        backgroundColor: "transparent"
                    });
                    //添加标记
                    this.marker[i] = new BMap.Marker(point, {icon: myIcon});
                    this.marker[i].setLabel(label);
                    //添加点击事件
                    this.marker[i].addEventListener("click", () => {
                        console.log("点击了标记");
                        // this.showFlag = true;
                        // this.pointName = data[i].name;
                        // this.imgUrl = require('../../assets/start1.jpg');
                    });
                    map.addOverlay(this.marker[i]);
                }
                //更改主题颜色
                map.setMapStyleV2({
                    styleId: '2c1ea5f5acd653e46e0bc9225ad63fb4'
                });
            } else {
                this.$message.error("查询失败！" + res.msg);
            }
            
        }
    }
}
