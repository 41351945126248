//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
      return {
        activeName: 'heightRisk'
      };
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab.name);
        this.$router.push("/" + tab.name);
      }
    }
  };
