//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      ip: '',
      projectList: [
        {
          id: 1,
          imgUrl: require('../assets/erp.gif'),
          name: 'erp'
        },
        {
          id: 2,
          imgUrl: require('../assets/manage.png'),
          name: 'manage'
        },
        {
          id: 3,
          imgUrl: require('../assets/mail.png'),
          name: 'mail'
        },
        {
          id: 4,
          imgUrl: require('../assets/poetry.png'),
          name: 'poetry'
        }
      ]
    }
  },
  created() {
    // this.queryIp();
  },
  methods: {
    imageClick(name) {
      switch (name) {
        case 'erp':
          window.location.href = location.origin + '/erp';
          break;
        case 'mail':
          break;
        case 'poetry':
          this.$router.push("/poetrySearch");
          break;
        case 'manage':
          this.$router.push("/login");
          break;
      }
    },
    async queryIp() {
      const {data:res} = await this.$http.post("common/queryIp");
      if (res.code === 'AAA') {
          this.ip = res.data;
      }
    }
  }
}
