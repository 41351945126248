//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      searchForm : {
        type : null,
        page: {
          current: 1,
          size: 10
        }
      },
      showConfigForm : {
        type : null,
        goodsId : null
      },
      typeList : [],
      goodsList : [],
      total : 0,
      buttonPermission: [0,1],
      buttonDisabled:  false,
      showFlag : false
    }
  },
  created() {
    this.checkPermission();
    this.queryShowType();
    this.queryShowConfig();
  },
  methods:{
      showAddShowConfig() {
          this.showFlag = true;
          if (this.goodsList.length === 0) {
              this.queryGoodsList();
          }
      },
    async addShowConfig() {
      this.buttonDisabled = true;
      const {data:res} = await this.$http.post("showConfig/addShowConfig", this.showConfigForm);
      if (res.code === 'AAA') {
          this.$message.success("新增成功！");
          this.showFlag = false;
          this.queryShowConfig();
      } else {
          this.$message.error("新增失败！" + res.msg);
      }
      this.buttonDisabled = false;
    },
    checkPermission() {
        this.buttonDisabled = this.$hasPermission(this.buttonPermission);
    },
    async queryShowType() {
      const {data:res} = await this.$http.post("showConfig/queryShowType");
      if (res.code === 'AAA') {
          this.typeList = res.data;
      } else {
          this.$message.error("查询展示类型失败！" + res.msg);
      }
    },
    async queryShowConfig() {
      const {data:res} = await this.$http.post("showConfig/queryShowConfig", this.searchForm);
      if (res.code === 'AAA') {
          this.dataList = res.data.records;
          this.total = res.data.total;
      } else {
          this.$message.error("查询展示类型失败！" + res.msg);
      }
    },
    async queryGoodsList() {
      const {data:res} = await this.$http.post("goods/listGoods", {page:{current:-1,size:-1}});
      if (res.code === 'AAA') {
          this.goodsList = res.data.records;
      } else {
          this.$message.error("查询商品失败！" + res.msg);
      }
    },
    deleteShowConfig(row) {
      this.$confirm('此操作将永久删除该展示配置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
          const {data:res} = await this.$http.post("showConfig/deleteShowConfig", {id:row.id});
          if (res.code === 'AAA') {
              this.$message.success("删除成功！");
              this.queryShowConfig();
          } else {
              this.$message.error("删除失败！" + res.msg);
          }
      }).catch(() => {
        this.$message.info("已取消删除");
      });
    }
  }
}
