//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      userForm : {
        name : '',
        account : '',
        age : '',
        tel : '',
        sex : '1',
        province : '',
        city : '',
        county : '',
        address : '',
        role : 0
      },
      roles : [
        {
          roleId : 0,
          roleText : '超级管理员'
        },
        {
          roleId : 1,
          roleText : '管理员'
        },
        {
          roleId : 2,
          roleText : '普通用户'
        },
        {
          roleId : 3,
          roleText : '游客'
        }
      ],
      riskInfo : {},
      provinceList : [],
      cityList : [],
      countyList : [],
      buttonPermission: [0,1],
      buttonDisabled:  false,
      addUserRule : {
        name : [
          {required: true, message: '请输入姓名', trigger: 'blur'}
        ],
        account : [
          {required: true, message: '请输入账号', trigger: 'blur'}
        ],
        age : [
          {required: true, message: '请输入年龄', trigger: 'blur'}
        ],
        tel : [
          {required: true, message: '请输入联系电话', trigger: 'blur'}
        ],
        province : [
          {required: true, message: '请选择省/直辖市', trigger: 'blur'}
        ],
        city : [
          {required: true, message: '请选择市', trigger: 'blur'}
        ],
        county : [
          {required: true, message: '请选择区/县', trigger: 'blur'}
        ]
      }
    }
  },
  created() {
    this.queryRiskInfo();
    this.checkPermission();
  },
  methods:{
    async queryRiskInfo() {
      const {data:res} = await this.$http.post("/common/queryRiskInfo");
      if (res.code === 'AAA') {
        this.riskInfo = res.data;
        this.provinceList = this.riskInfo.provinceList;
        this.cityList = this.riskInfo.cityList;
        this.countyList = this.riskInfo.countyList;
      }
    },
    addUser() {
      this.$refs.addUserFormRef.validate(async valid => {
        if (valid) {
          const {data:res} = await this.$http.post("user/addUser", this.userForm);
          if (res.code === 'AAA') {
            this.$message.info("新增成功！");
          } else {
            this.$message.error("新增失败！" + res.msg);
          }
        }

      });
    },
    resetCity() {
      this.userForm.city = "";
      this.userForm.county = "";
    },
    resetCounty() {
      this.userForm.county = "";
    },
    checkPermission() {
      this.buttonDisabled = this.$hasPermission(this.buttonPermission);
    }
  }
}
