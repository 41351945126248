//
//
//
//
//

export default {
    data() {
        return {
            option: {
                title: {
                    text: '技术熟练度',
                    left: 'center'
                },
                tooltip: {},
                legend: {
                    data: ['Allocated Budget']
                },
                radar: {
                    // shape: 'circle',
                    indicator: [
                        { name: 'HTML', max: 100 },
                        { name: 'CSS', max: 100 },
                        { name: 'JavaScript', max: 100 },
                        { name: 'Vue', max: 100 },
                        { name: 'Java', max: 100 },
                        { name: 'Spring', max: 100 },
                        { name: 'MySQL', max: 100},
                        { name: 'CocosCreate', max: 100}
                    ]
                },
                series: [{
                    name: '熟练度',
                    type: 'radar',
                    data: [
                        {
                            value: [80, 80, 70, 50, 90, 90, 80, 30]
                        }
                    ]
                }]
            }
        }
    },
    mounted() {
        
    },
    methods: {
    }
}
