//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default  {
      data() {
         return  {
          allCategory:  [] ,
          dialogFormVisible:  false ,
          category: {},
          parentCategory: {},
          buttonPermission: [0,1],
          buttonDisabled:  false
        }
      },
      created() {
        this.categoryTree();
        this.checkPermission();
      },
      methods: {
        async categoryTree() {
          const {data:res} = await this.$http.post("category/categoryTree");
            if (res.code === 'AAA') {
                this.allCategory = res.data;
            } else {
              this.$message.error("查询类别失败！" + res.msg);
            }
        },
        async updateCategory(data) {
            //修改类别信息
            this.dialogFormVisible =  true ;
            this.category = data;
        },
        addChild(data) {
            this.dialogFormVisible =  true ;
            let newChild = {
                categoryName: '',
                categoryLevel: 0,
                parentId: data === null ? 0 : data.id,
                idPath: '',
                children: []
            };
            this.category = newChild;
            this.parentCategory = data;
        },
        async addOrUpdateCategory() {
            if (this.category.id) {
                //修改类别
                const {data:res} = await this.$http.post("category/updateCategory", this.category);
                if (res.code === 'AAA') {
                    this.dialogFormVisible =  false ;
                } else {
                    this.$message.error("修改类别失败！" + res.msg);
                }
                return;
            } else {
                //新增类别
                const {data:res} = await this.$http.post("category/addCategory", this.category);
                if (res.code === 'AAA') {
                    this.dialogFormVisible =  false ;
                    this.category.id = res.data;
                    if (this.parentCategory) {
                        this.parentCategory.children.push(this.category);
                    }else {
                        this.allCategory.push(this.category);
                    }
                } else {
                    this.$message.error("新增类别失败！" + res.msg);
                }
            }
        },
        async deleteCategory(node, data) {
            //调用接口删除类别
            const {data:res} = await this.$http.post("category/deleteCategory?categoryId=" + data.id);
            if (res.code === 'AAA') {
                if (node.parent) {
                    let parent = node.parent;
                    let children = parent.data.children || parent.data;
                    let index = children.findIndex(d => d.id === data.id);
                    children.splice(index, 1);
                }
            } else {
                this.$message.error("删除类别失败！" + res.msg);
            }
        },
        checkPermission() {
            this.buttonDisabled = this.$hasPermission(this.buttonPermission);
        }
      }
    }
