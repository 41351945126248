//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default  {
  data() {
     return  {
      allMenu:  [] ,
      dialogFormVisible:  false ,
      menu: {},
      parentMenu: {},
      buttonPermission: [0,1],
      buttonDisabled:  false ,
      roles : [
        {
            roleId : '0',
            roleText : '超级管理员'
        },
        {
            roleId : '1',
            roleText : '管理员'
        },
        {
            roleId : '2',
            roleText : '普通用户'
        },
        {
            roleId : '3',
            roleText : '游客'
        }
      ]
    }
  },
  created() {
    this.menuTree();
    this.checkPermission();
  },
  methods: {
    async menuTree() {
      const {data:res} = await this.$http.post("menu/menuTree");
        if (res.code === 'AAA') {
            this.allMenu = res.data;
        } else {
          this.$message.error("查询菜单失败！" + res.msg);
        }
    },
    async updateMenu(data) {
        //修改菜单信息
        this.dialogFormVisible =  true ;
        this.menu = data;
    },
    addChild(data) {
        this.dialogFormVisible =  true ;
        let newChild = {
            name: '',
            url: '',
            icon: '',
            sort: 0,
            roleIdList: [],
            parentId: data === null ? null : data.id,
            status: true,
            children: []
        };
        this.menu = newChild;
        this.parentMenu = data;
    },
    async addOrUpdateMenu() {
        if (this.menu.id) {
            //修改菜单
            const {data:res} = await this.$http.post("menu/updateMenu", this.menu);
            if (res.code === 'AAA') {
                this.dialogFormVisible =  false ;
            } else {
                this.$message.error("修改菜单失败！" + res.msg);
            }
            return;
        } else {
            //新增菜单
            const {data:res} = await this.$http.post("menu/addMenu", this.menu);
            if (res.code === 'AAA') {
                this.dialogFormVisible =  false ;
                this.menu.id = res.data;
                if (this.parentMenu) {
                    this.parentMenu.children.push(this.menu);
                }else {
                    this.allMenu.push(this.menu);
                }
            } else {
                this.$message.error("新增菜单失败！" + res.msg);
            }
        }
    },
    async deleteMenu(node, data) {
        //调用接口删除菜单
        const {data:res} = await this.$http.post("menu/deleteMenu?menuId=" + data.id);
        if (res.code === 'AAA') {
            if (node.parent) {
                let parent = node.parent;
                let children = parent.data.children || parent.data;
                let index = children.findIndex(d => d.id === data.id);
                children.splice(index, 1);
            }
        } else {
            this.$message.error("删除菜单失败！" + res.msg);
        }
    },
    checkPermission() {
        this.buttonDisabled = this.$hasPermission(this.buttonPermission);
    }
  }
}
