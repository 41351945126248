//
//
//
//
//

export default {
    data() {
        return {
            option: {
                title: {
                    text: '武汉市近期气温变化'
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data:['最低气温(℃)', '最高气温(℃)']
                },
                toolbox: {
                    show: true,
                    feature: {
                    dataZoom: {
                        yAxisIndex: 'none'
                    },
                    dataView: { readOnly: false },
                    magicType: { type: ['line', 'bar'] },
                    restore: {},
                    saveAsImage: {}
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: []
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: '{value} °C'
                    }
                },
                series: [
                    {
                        name: '最低气温(℃)',
                        type: 'line',
                        color: '#1a66b5',
                        markLine: {
                            symbol: 'none',
                            data: [
                                {xAxis: '04/25', name: '今天'}
                            ]
                        },
                        data: []
                    },
                    {
                        name: '最高气温(℃)',
                        type: 'line',
                        color: '#FF0000',
                        data: []
                    }
                ]
            }
        }
    },
    mounted() {
        
    },
    created () {
        this.queryWeather();
    },
    methods: {
        //调用接口查询武汉最近半个月的天气情况
        async queryWeather() {
            const {data:res} = await this.$http.post("/common/queryWeatherByCode?code=57494");
            if (res.code === 'AAA' && res.data) {
                let tempchart = res.data.tempchart;
                for(let temp of tempchart) {
                    this.option.xAxis.data.push(temp.time.substring(5));
                    this.option.series[0].data.push(temp.min_temp);
                    this.option.series[1].data.push(temp.max_temp);
                }
                //获取当前日期
                let date = new Date();
                let month = date.getMonth() + 1;
                let day = date.getDate();
                if (month < 10) {
                    month = "0" + month;
                }
                if (day < 10) {
                    day = "0" + day;
                }
                let today = month + "/" + day;
                //设置今天的标记
                this.option.series[0].markLine.data[0].xAxis = today;
            } else {
                this.$message.error("查询天气信息失败！");
            }
        }
    }
}
