//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      searchForm : {
        name : '',
        role : ''
      },
      roles : [
        {
          roleId : 0,
          roleText : '超级管理员'
        },
        {
          roleId : 1,
          roleText : '管理员'
        },
        {
          roleId : 2,
          roleText : '普通用户'
        },
        {
          roleId : 3,
          roleText : '游客'
        }
      ],
      buttonPermission: [0,1],
      buttonDisabled:  false,
      dataList : [],
      pageSize : 10,
      currentPage : 1,
      total : 0
    }
  },
  created() {
    this.queryUserList();
    this.checkPermission();
  },
  methods: {
    async queryUserList() {
      const {data:res} = await this.$http.post("user/userList", this.searchForm);
      if (res.code === 'AAA') {
        this.dataList = res.data.items;
        this.total = res.data.total;
      } else {
        this.$message.error("查询失败！" + res.msg);
      }
    },
    async downloadUserInfo() {
      //调用后台接口，下载文件
      const response = await this.$http({
        url: 'user/downloadUserInfo',
        method: 'POST',
        data: this.searchForm,
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', '用户信息.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    checkPermission() {
        this.buttonDisabled = this.$hasPermission(this.buttonPermission);
    }
  }
}
