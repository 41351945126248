//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AddOrUpdateGoods from '../../components/goods/AddOrUpdateGoods.vue';
  export default {
    components: {
      AddOrUpdateGoods
    },
    data() {
      return {
        searchForm : {
          goodsName : '',
          categoryId : '',
          page : {
              current : 1,
              size : 10
          }
        },
        categorys : [],
        buttonPermission: [0,1],
        dataList : [],
        total : 0,
        goods : {},
        showAddOrUpdateGoods : false
      }
    },
    created() {
      this.queryGoodsList();
      this.queryCategoryList();
    },
    methods: {
      async queryGoodsList() {
        const {data:res} = await this.$http.post("goods/listGoods", this.searchForm);
        if (res.code === 'AAA') {
          this.dataList = res.data.records;
          this.total = res.data.total;
        } else {
          this.$message.error("查询失败！" + res.msg);
        }
      },
      async queryCategoryList() {
        const {data:res} = await this.$http.post("category/listCategory");
        if (res.code === 'AAA') {
            this.categorys = res.data;
        } else {
            this.$message.error("查询类别失败！" + res.msg);
        }
      },
      handleSizeChange(val) {
        this.searchForm.page.size = val;
      },
      handleCurrentChange(val) {
        this.searchForm.page.current = val;
      },
      deleteGoods(row) {
        this.$confirm('确认删除这条数据吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post("goods/deleteGoods", {id:row.id}).then(res => {
            if (res.data.code === 'AAA') {
              this.queryGoodsList();
            } else {
              this.$message.error("删除失败！" + res.data.msg);
            }
          });
          this.$message.success("删除成功!");
        }).catch(() => {
          this.$message.info("已取消删除");          
        });
      },
      showAddGoods() {
        this.showAddOrUpdateGoods = true;
      },
      showUpdateGoods(row) {
        this.goods = row;
        this.showAddOrUpdateGoods = true;
      }
    }
  }
