import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Welcome from '../components/Welcome.vue'
import Login from '../components/Login.vue'
import NocvAreaList from '../components/nocv/NocvAreaList.vue'
import HeightRisk from '../components/nocv/HeightRisk.vue'
import MiddleRisk from '../components/nocv/MiddleRisk.vue'
import AddUser from '../components/user/AddUser.vue'
import UserList from '../components/user/UserList.vue'
import AddData from '../components/data/AddData.vue'
import QueryData from '../components/data/QueryData.vue'
import MapPointManage from '../components/data/MapPointManage.vue'
import GanttChart from '../components/gantt/GanttChart.vue'
import NoticeList from '../components/notice/NoticeList.vue'
import AddNotice from '../components/notice/AddNotice.vue'
import PlayGame from '../components/game/Game.vue'
import Love from '../components/game/Love.vue'
import AddMenu from '../components/menu/AddMenu.vue'
import Nacos from '../components/server/Nacos.vue'
import Sentinel from '../components/server/Sentinel.vue'
import BarChart from '../components/echarts/BarChart.vue'
import LineChart from '../components/echarts/LineChart.vue'
import PieChart from '../components/echarts/PieChart.vue'
import RadarChart from '../components/echarts/RadarChart.vue'
import MapChart from '../components/echarts/MapChart.vue'
import StartMap from '../components/echarts/StartMap.vue'
import TreeChart from '../components/echarts/TreeChart.vue'
import GraphChart from '../components/echarts/GraphChart.vue'
import GaugeChart from '../components/echarts/GaugeChart.vue'
import FunnelChart from '../components/echarts/FunnelChart.vue'
import ParallelChart from '../components/echarts/ParallelChart.vue'
import SankeyChart from '../components/echarts/SankeyChart.vue'
import PoetrySearch from '../components/poetry/PoetrySearch.vue'
import CategoryManage from '../views/category/CategoryManage.vue'
import AddOrUpdateGoods from '../components/goods/AddOrUpdateGoods.vue'
import GoodsList from '../views/goods/GoodsList.vue'
import ShowConfig from '../views/showConfig/ShowConfig.vue'
import dayjs from 'dayjs'

Vue.prototype.$dayjs = dayjs

import axios from 'axios'
axios.defaults.baseURL = '/haohua-web/api';
// axios.defaults.baseURL = 'http://localhost:8070/haohua-web/api';
//请求携带cookie
// axios.defaults.withCredentials = true;
//拦截请求设置请求头
axios.interceptors.request.use(config => {
  config.headers.Authorization = window.sessionStorage.getItem('token');
  config.headers.accessToken = window.sessionStorage.getItem('accessToken');
  return config;
});
//响应拦截器
axios.interceptors.response.use(response => {
  const status = response.status;
    if(status === 403){
      Vue.prototype.$message.error("登录失效，请重新登录");
      window.location.href = "/#/login";
    }else if(status !== 200){
      Vue.prototype.$message.error("服务异常，请稍后再试");
    }
    return response;
}, error => {
  const status = error.response.status;
  if(status === 403){
    Vue.prototype.$message.error("登录失效，请重新登录");
    window.location.href = "/#/login";
  }else if(status !== 200){
    Vue.prototype.$message.error("服务异常，请稍后再试");
  }
  return Promise.reject(error);
});
Vue.prototype.$http = axios

//定义一个全局函数判断当前用户是否有权限访问某个菜单
Vue.prototype.$hasPermission = function (needRole) {
  //获取当前用户的所有权限
  const role = JSON.parse(window.sessionStorage.getItem('user')).role;
  //判断needRole中是否存在role
  return !needRole.includes(role);
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/Welcome'
  },
  {
    path: '/Welcome',
    component: Welcome
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/love',
    component: Love
  },
  {
    path: '/startMap',
    component: StartMap,
    meta: { title: '星图' }
  },
  {
    path: '/mapPointManage',
    component: MapPointManage
  },
  {
    path: '/poetrySearch',
    component: PoetrySearch
  },
  {
    path: '/home',
    component: Home,
    children: [
      {
        path: '/nocvAreaList',
        component: NocvAreaList,
        children: [
          {
            path: '/heightRisk',
            component: HeightRisk
          },
          {
            path: '/middleRisk',
            component: MiddleRisk
          }
        ]
      },
      {
        path: '/addUser',
        component: AddUser
      },
      {
        path: '/UserList',
        component: UserList
      },
      {
        path: '/addData',
        component: AddData
      },
      {
        path: '/queryData',
        component: QueryData
      },
      {
        path: '/ganttChartDetail',
        component: GanttChart
      },
      {
        path: '/noticeList',
        component: NoticeList
      },
      {
        path: '/addNotice',
        component: AddNotice
      },
      {
        path: '/playGame',
        component: PlayGame
      },
      {
        path: '/addMenu',
        component: AddMenu
      },
      {
        path: '/nacos',
        component: Nacos
      },
      {
        path: '/sentinel',
        component: Sentinel
      },
      {
        path: '/barChart',
        component: BarChart
      },
      {
        path: '/lineChart',
        component: LineChart
      },
      {
        path: '/pieChart',
        component: PieChart
      },
      {
        path: '/radarChart',
        component: RadarChart
      },
      {
        path: '/mapChart',
        component: MapChart
      },
      {
        path: '/treeChart',
        component: TreeChart
      },
      {
        path: '/graphChart',
        component: GraphChart
      },
      {
        path: '/gaugeChart',
        component: GaugeChart
      },
      {
        path: '/funnelChart',
        component: FunnelChart
      },
      {
        path: '/parallelChart',
        component: ParallelChart
      },
      {
        path: '/sankeyChart',
        component: SankeyChart
      },
      {
        path: '/categoryManage',
        component: CategoryManage
      },
      {
        path: '/addOrUpdateGoods',
        component: AddOrUpdateGoods
      },
      {
        path: '/goodsList',
        component: GoodsList
      },
      {
        path: '/showConfig',
        component: ShowConfig
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
