//
//
//
//
//

export default {
    data() {
        return {
            option: {
                title: {
                    text: '全国各省城市数量',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left'
                },
                series: [{
                    name: '城市数量',
                    type: 'pie',
                    data: []
                }]
            }
        }
    },
    mounted() {
        this.queryCityCount();
    },
    methods: {
        //调用接口查询全国各省城市数量
        async queryCityCount() {
            const {data:res} = await this.$http.post("/common/queryCityCount");
            if (res.code === 'AAA' && res.data) {
                this.option.series[0].data = res.data;
            } else {
                this.$message.error("查询天气信息失败！");
            }
        }
    }
}
