//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      riskAreaList: []
    }
  },
  created() {
    //调用中高风险地区查询接口
    this.queryNocvCity();
  },
  methods: {
    async queryNocvCity() {
      const {data:res} = await this.$http.post("common/queryNocvCity");
      console.info(res);
      this.riskAreaList = res.data;
    }
  }
}
