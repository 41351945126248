//
//
//
//
//

export default {
    data() {
        return {
            option: {
                title: {
                    text: '系统菜单',
                    left: 'center'
                },
                tooltip: {},
                legend: {
                },
                series: [{
                    type: 'tree',
                    data: [],
                    top: '5%',
                    left: '10%',
                    bottom: '2%',
                    right: '15%',
                    symbolSize: 7,
                    label: {
                        position: 'left',
                        verticalAlign: 'middle',
                        align: 'right'
                    },
                    leaves: {
                    label: {
                        position: 'right',
                        verticalAlign: 'middle',
                        align: 'left'
                    }
                    },
                    emphasis: {
                        focus: 'descendant'
                    },
                    expandAndCollapse: true,
                    animationDuration: 550,
                    animationDurationUpdate: 750
                }]
            }
        }
    },
    mounted() {
        
    },
    created() {
        this.queryMenuList();
    },
    methods: {
        async queryMenuList() {
            const { data: res } = await this.$http.post("/menu/menuTree");
            let menuTree = res.data;
            let node = {
                name: '菜单',
                children: []
            };
            for (let menu of menuTree) {
                node.children.push(this.setChildren(menu, node.children));
            }
            this.option.series[0].data.push(node);
        },
        setChildren(menu) {
            if (menu.children && menu.children.length > 0) {
                let node = {
                    name: menu.name,
                    children: []
                };
                for (let child of menu.children) {
                    node.children.push(this.setChildren(child));
                }
                return node;
            } else {
                let node = {
                    name: menu.name,
                    vaule: menu.id
                };
                return node;
            }
        }

    }
}
