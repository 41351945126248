//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      searchForm : {
        field1 : '',
        field2 : ''
      },
      dataList : [],
      pageSize : 10,
      currentPage : 1,
      total : 0
    }
  },
  created() {
    this.queryDataList();
  },
  methods: {
    async queryDataList() {
      const {data:res} = await this.$http.post("data/queryData", {});
      if (res.code === 'AAA') {
        this.dataList = res.data.items;
        this.total = res.data.total;
      } else {
        this.$message.error("查询失败！" + res.msg);
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    }
  }
}
