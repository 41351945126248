//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      noticeList: []
    }
  },
  created() {
    this.queryNoticeList();
  },
  methods: {
    async queryNoticeList() {
      const {data:res} = await this.$http.post("/notice/noticeList?pageNum=1");
      this.noticeList = res.data.items;
    }
  }
}
