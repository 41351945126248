import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import 'echarts'
import ECharts from 'vue-echarts'
import './plugins/vintage'
import BaiduMap from './components/vue-baidu-map'
import LemonIMUI from 'lemon-imui'
import 'lemon-imui/dist/index.css'
Vue.use(LemonIMUI);

Vue.config.productionTip = false
Vue.component('v-chart', ECharts)
Vue.use(BaiduMap, {
  ak: 'Fpv3bIyrMzCQ8yzBVbNZkR9vMfPKYBuY'
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
