//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            center: {lng: 0, lat: 0},
            zoom: 5
        }
    },
    mounted() {
        
    },
    methods: {
        async queryPosition() {
            const {data:res} = await this.$http.post("/common/queryPosition");
            if (res.code === 'AAA' && res.data) {
                this.center.lng = res.data.x
                this.center.lat = res.data.y
                this.zoom = 13
            }
        },
        handler ({BMap, map}) {
            this.queryPosition();
        },
        currentPosition (e) {
            console.log("定位成功" + JSON.stringify(e.addressComponent))
        }
    }
}
