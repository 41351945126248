//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const getTime = () => new Date().getTime();
const generateRandId = () => Math.random().toString(36).substr(-8);
var currentUser = {
  id : null
};
var ws;
export default {
  data() {
    return {
      user: {
        id: "",
        displayName: "",
        avatar: "",
      },
      allMenu:  [],
      chatShow: false,
      historyMsgList: [],
      asideShow: true
    };
  },
  created() {
    this.queryMenuList();
    this.getLateLyMsgList();
    //当前用户信息
    currentUser = JSON.parse(window.sessionStorage.getItem('user'));
    this.user.id = currentUser.id;
    this.user.displayName = currentUser.name;
    //websocket
    ws = new WebSocket("wss://"+location.host+"/haohua-web/websocket/"+currentUser.id);
    ws.onopen = function() {
      console.log("连接成功");
    };
    ws.onclose = function() {
      console.log("连接关闭");
    };
  },
  mounted() {
    let userAgent = navigator.userAgent.toLowerCase();
    // 用 test 匹配浏览器信息
    if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(userAgent)) {
        document.getElementById("chat_div").style.position = "fixed";
        document.getElementById("chat_div").style.top = "0px";
        document.getElementById("chat_div").style.height = "100vh";
        this.hideAside();
    }
    const { IMUI } = this.$refs;
    IMUI.initContacts([
      {
        id: 1000000000,
        displayName: "郭哈哈",
        avatar: "",
        type: "single",
        index: "T",
        unread: 0,
        lastSendTime: getTime(),
        lastContent: "",
      },
    ]);
    setTimeout(() => {
      IMUI.changeContact(1000000000);
    }, 2000);
    ws.onmessage = function(e) {
      console.log("收到服务端的消息：" + e.data);
      let time = new Date().getTime();
      const data = {
        id: time,
        status: 'succeed',
        type: 'text',
        sendTime: time,
        content: e.data,
        toContactId: 1000000000,
        fromUser:{
          id:1000000000,
          displayName:'郭哈哈',
          avatar:'',
        }
      };
      IMUI.appendMessage(data);
    };
  },
  methods: {
    async queryMenuList() {
      const { data: res } = await this.$http.post("/menu/menuTree");
      this.allMenu = res.data;
      location.href = "#/noticeList";
    },
    handleMessageClick(e, key, message) {
      const { IMUI } = this.$refs;
      if (key == "status" && message.status === "failed") {
        IMUI.updateMessage({
          id:message.id,
          toContactId:message.toContactId,
          status: "going",
          content: "重新发送消息...",
        });
        setTimeout(() => {
          IMUI.updateMessage({
            id:message.id,
            toContactId:message.toContactId,
            status: "failed",
            content: "还是发送失败",
          });
        }, 2000);
      }
    },
    handleSend(message, next, file) {
      console.log(message, next, file);
      ws.send(message.content);
      setTimeout(() => {
        next();
      }, 1000);
    },
    handlePullMessages(contact, next) {
      const { currentContactId } = this.$refs.IMUI;
      const otherUser = {
        id: contact.id,
        avatar: contact.avatar,
        displayName: contact.displayName,
      };
      const message = (content, fromUser = this.user) => {
        return {
          id: generateRandId(),
          status: "succeed",
          type: "text",
          sendTime: getTime(),
          content,
          toContactId: currentContactId,
          fromUser,
        };
      };
      const messages = [];
      for (const msg of this.historyMsgList) {
        if (msg.fromId === currentUser.id) {
          messages.push(message(msg.msg));
        } else {
          messages.push(message(msg.msg, otherUser));
        }
      }
      if (messages.length === 0) {
        messages.push(message("你好", otherUser));
      }
      next(messages, true);
    },
    showChat() {
      this.chatShow = true;
    },
    hideChat() {
      this.chatShow = false;
    },
    async getLateLyMsgList() {
      const { data: res } = await this.$http.post("/msg/getLateLyMsgList");
      if (res.code === 'AAA' && res.data != null) {
        this.historyMsgList = res.data;
      }
    },
    hideAside() {
      this.asideShow = false;
    },
    showAside() {
      this.asideShow = true;
    }
  }
};

