//
//
//
//
//
//
//
//
//

import gantt from 'dhtmlx-gantt'
export default {
  name: 'gantt',
  props: {
    tasks: {
      type: Object,
      default() {
        return {
          data: [
            { id: 1, text: '任务1', start_date: '15-04-2017', personName: '张总', duration: 3, progress: 0.6 },
            { id: 2, text: '任务2', start_date: '18-04-2017', personName: '李总', duration: 3, progress: 0.4 },
            { id: 3, text: '任务2-1', start_date: '20-04-2017', personName: '赵总', duration: 3, progress: 0.4, parent: 2 }
          ],
          links: [
            { id: 1, source: 1, target: 2, type: '0' }
          ]
        }
      }
    }
  },
  methods:{
    buildWbsCode(task) {
        let formal = gantt.getWBSCode(task);
        if (task.parent == "") {
            return 0;
        } else {
            let codeArray = formal.split(".");
            codeArray.shift();
            return codeArray.join(".");
        }
    },
    addColumn() {
      console.info("新增列");
      gantt.config.columns.push({
          name: "aaa",
          label: "新增列",
          align: "center",
          resize: true,
          min_width: 100,
          width: 100
      });
      // 初始化
      gantt.init(this.$refs.gantt)
    }
  },
  mounted() {
    console.info("----------")
    // 在时间线上增加一行年份显示
    gantt.config.subscales = [
      {
        unit: 'year',
        step: 1,
        date: '%Y'
      }
    ]
    // 初始化
    gantt.init(this.$refs.gantt)
    // 数据解析
    gantt.parse(this.$props.tasks)
    //列配置
    // gantt.config.columns = [
    //     {
    //         name: "wbs",
    //         label: "编码",
    //         width: 40,
    //         template: 0,
    //         align: "left",
    //         resize: true
    //     },
    //     {
    //         name: "text",
    //         label: "名称",
    //         align: "left",
    //         tree: true,
    //         min_width: 200,
    //         width: 200,
    //         resize: true,
    //     },
    //     {
    //         name: "start_date",
    //         label: "开始时间",
    //         align: "center",
    //         resize: true,
    //         min_width: 100,
    //         width: 100,
    //         template: function(obj) {
    //             return obj.start_date ? obj.start_date : "";
    //         }
    //     },
    //     {
    //         name: "end_date",
    //         label: "完成时间",
    //         align: "center",
    //         resize: true,
    //         min_width: 100,
    //         width: 100,
    //         template: function(obj) {
    //             return obj.end_date ? obj.end_date : "";
    //         }
    //     },
    //     {
    //         name: "plan_start",
    //         label: "计划开始时间",
    //         align: "center",
    //         resize: true,
    //         hide: false,
    //         min_width: 100,
    //         width: 100,
    //         template: function(obj) {
    //             return obj.plan_start ? obj.plan_start : "";
    //         }
    //     },
    //     {
    //         name: "plan_end",
    //         label: "计划完成时间",
    //         align: "center",
    //         resize: true,
    //         hide: false,
    //         min_width: 100,
    //         width: 100,
    //         // template: function(obj) {
    //         //     return obj.plan_end ? obj.plan_end : "";
    //         // }
    //     },
    //     {
    //         name: "actual_start",
    //         label: "实际开始时间",
    //         align: "center",
    //         resize: true,
    //         hide: true,
    //         min_width: 100,
    //         width: 100,
    //         template: function(obj) {
    //             return obj.actual_start ? obj.actual_start : "";
    //         }
    //     },
    //     {
    //         name: "actual_end",
    //         label: "实际完成时间",
    //         align: "center",
    //         resize: true,
    //         hide: true,
    //         min_width: 100,
    //         width: 100,
    //         template: function(obj) {
    //             return obj.actual_end ? obj.actual_end : "";
    //         }
    //     },
    //     {
    //         name: "duration",
    //         label: "工期（天）",
    //         align: "center",
    //         resize: true,
    //         min_width: 80,
    //         width: 80
    //     },
    //     {
    //         name: "progress",
    //         label: "完成百分比",
    //         align: "center",
    //         resize: true,
    //         min_width: 100,
    //         width: 100,
    //         template: function(obj) {
    //             return obj.progress
    //                 ? Math.round(obj.progress * 10000) / 100 + "%"
    //                 : "";
    //         }
    //     },
    //     {
    //         name: "budget",
    //         label: "预算资源",
    //         align: "center",
    //         resize: true,
    //         min_width: 100,
    //         width: 100,
    //         template: function(obj) {
    //             return obj.budget ? obj.budget : "0";
    //         }
    //     },
    //     { name: "add", label: "", width: 44, resize: true, hide: true }
    // ];
    //展示配置滚动条
    gantt.config.layout = {
        css: "gantt_container",
        cols: [
            {
                width: 720,
                rows: [
                    {
                        view: "grid",
                        scrollX: "gridScroll",
                        scrollable: true,
                        scrollY: "scrollVer"
                    },
                    { view: "scrollbar", id: "gridScroll", group: "horizontal" }
                ]
                // gravity: 2
            },
            { resizer: true, width: 1 },
            {
                rows: [
                    { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },
                    { view: "scrollbar", id: "scrollHor", group: "horizontal" }
                ]
            },
            { view: "scrollbar", id: "scrollVer" }
        ]
    };
  }
}
