//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      dataForm : {
        field1 : '',
        field2 : '',
        field3 : new Date(),
        field33 : '',
        field4 : false,
        field5 : [],
        field6 : '1',
        field7 : ''
      }
    }
  },
  methods: {
    async addData() {
      this.dataForm.field3 = this.$dayjs(this.dataForm.field3).format('YYYY-MM-DD HH:mm:ss');
      const {data:res} = await this.$http.post("data/addData", this.dataForm);
      if (res.code === 'AAA') {
        this.$message.success("新增成功！");
      } else {
        this.$message.error("新增失败！" + res.msg);
      }
    }
  }
}
