//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      keyword: '',
      pageNum: 1,
      poetryList: [],
      poetry: {
        title: '',
        author: '',
        paragraphs: ''
      },
      timer: null,
      lastTimer: null,
      showFlag: false,
      loadMoreFlag: false,
      loading: false
    }
  },
  created() {
    // this.queryNoticeList();
  },
  methods: {
    searchPoetry() {
      //防抖
      if (this.timer) {
          clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
          this.timer = null;
          this.loadMoreFlag = false;
          this.queryPoetryList();
      }, 800);
    },
    async queryPoetryList() {
      if (!this.keyword) {
          this.poetryList = [];
          return;
      }
      if(!this.loadMore) {
          this.pageNum = 1;
      }
      this.loading = true;
      this.$http.defaults.baseURL = '/poetry/api';
      let {data:res} = await this.$http.post('/poetry/searchPoetry', {
          keyword: this.keyword,
          pageNum: this.pageNum
      });
      if (res.code === 'AAA') {
          if(this.loadMore) {
            this.poetryList = this.poetryList.concat(res.data);
          } else {
            this.poetryList = res.data;
          }
      }
      this.loading = false;
    },
    showDetail(poetry) {
      //复制对象
      this.poetry = {...poetry};
      let paragraphsArray = poetry.paragraphs;
      let paragraphsStr = '';
      for(let paragraphs of paragraphsArray) {
          paragraphsStr += paragraphs + '<br/><br/>';
      }
      this.poetry.paragraphs = paragraphsStr;
      this.showFlag = true;
    },
    loadMore() {
      
        //数据展示div高度
        let dataListHeight = document.getElementsByClassName("card_div")[0].clientHeight;
        //滚动条离数据展示div上边界距离
        let scrollTop = document.getElementsByClassName("card_div")[0].scrollTop;
        //数据内容实际高度
        let sumHeight = document.getElementById("point").offsetTop;
        //滑到底了
        if(dataListHeight + scrollTop >= sumHeight-30) {
            //节流
            let currentTime = Date.now()
            if (currentTime - this.lastTimer > 1000) {
              this.lastTimer = currentTime
              this.pageNum++;
              this.loadMoreFlag = true;
              this.queryPoetryList();
            }
        }
    }
  }
}
