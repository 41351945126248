//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      goodsForm : {
        id: null,
        goodsName : '',
        stock : 0,
        goodsPrice : 0,
        smallImg : '',
        bigImg : '',
        categoryId : '',
        remark : ''
      },
      headers : {
          'Authorization' : window.sessionStorage.getItem('token')
      },
      smallImg : '',
      bigImg : '',
      categorys : [],
      buttonPermission: [0,1],
      buttonDisabled:  false,
      addGoodsRule : {
        goodsName : [
          {required: true, message: '请输入名称', trigger: 'blur'}
        ],
          stock : [
              {required: true, message: '请输入库存', trigger: 'blur'}
          ],
        goodsPrice : [
          {required: true, message: '请输入价格', trigger: 'blur'}
        ],
        categoryId : [
          {required: true, message: '请选择类别', trigger: 'blur'}
        ],
        smallImg : [
          {required: true, message: '请上传小图', trigger: 'blur'}
        ],
        bigImg : [
          {required: true, message: '请上传大图', trigger: 'blur'}
        ],
        remark : [
          {required: true, message: '请输入描述', trigger: 'blur'}
        ]
      }
    }
  },
  props: ['goods'],
  created() {
    this.checkPermission();
    this.queryCategoryList();
    this.goodsForm = this.goods;
    this.smallImg = this.goods.smallImg;
    this.bigImg = this.goods.bigImg;
  },
  methods:{
    uploadSmallImgSuccess(res, file) {
      this.smallImg = URL.createObjectURL(file.raw);
      this.goodsForm.smallImg = res.data;
    },
    uploadBigImgSuccess(res, file) {
      this.bigImg = URL.createObjectURL(file.raw);
      this.goodsForm.bigImg = res.data;
    },
    beforeSmallImgUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt200K = file.size / 1024 < 200;

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 格式!');
      }
      if (!isLt200K) {
        this.$message.error('上传商品小图不能超过 200KB!');
      }
      return isJPG && isLt200K;
    },
    beforeBigImgUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传商品大图不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    addOrUpdateGoods() {
      this.$refs.addGoodsFormRef.validate(async valid => {
        if (valid) {
          this.buttonDisabled = true;
          if (this.goodsForm.id) {
            const {data:res} = await this.$http.post("goods/updateGoods", this.goodsForm);
            if (res.code === 'AAA') {
              this.$message.success("修改成功！");
            } else {
              this.$message.error("修改失败！" + res.msg);
            }
          } else {
            const {data:res} = await this.$http.post("goods/addGoods", this.goodsForm);
            if (res.code === 'AAA') {
              this.$message.success("新增成功！");
            } else {
              this.$message.error("新增失败！" + res.msg);
            }
          }
          this.buttonDisabled = false;
          this.cancel();
        }

      });
    },
    checkPermission() {
        this.buttonDisabled = this.$hasPermission(this.buttonPermission);
    },
    async queryCategoryList() {
      const {data:res} = await this.$http.post("category/listCategory");
      if (res.code === 'AAA') {
          this.categorys = res.data;
      } else {
          this.$message.error("查询类别失败！" + res.msg);
      }
    },
    cancel() {
      this.$parent.showAddOrUpdateGoods = false;
    }
  }
}
