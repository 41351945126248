//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      loginForm : {
        name : '',
        password : ''
      },
      loginFormRule: {
        name: [
          {required: true, message: '请输入用户名', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'}
        ]
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    login() {
      this.$refs.loginFormRef.validate(async valid => {
        const {data:res} = await this.$http.post("/user/login", this.loginForm).catch(err => {
        this.$message.error("用户名或密码错误");
      });
        if (res.code === 'AAA' && res.data != null) {
          this.$message.success("登录成功");
          console.info("登录成功"+res.data.token);
          window.sessionStorage.setItem("token", res.data.token);
          //将接口返回的user对象存在sessionStorage中
          window.sessionStorage.setItem("user", JSON.stringify(res.data.user));
          this.$router.push("/home");
        } else {
          this.$message.error("用户名或密码错误");
          console.info(res.msg);
        }
      });
    },
    async visitor() {
      const {data:res} = await this.$http.post("/user/visitor", {}).catch(err => {
        this.$message.error("抱歉，暂不支持游客访问");
      });
      if (res.code === 'AAA' && res.data != null) {
        this.$message.success("登录成功");
        window.sessionStorage.setItem("token", res.data.token);
        //将接口返回的user对象存在sessionStorage中
        window.sessionStorage.setItem("user", JSON.stringify(res.data.user));
        this.$router.push("/home");
      } else {
        this.$message.error("抱歉，暂不支持游客访问");
      }
    }
  }
};
